<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="row justify-content-end mb-2">
        <v-btn @click="adicionarFormulario" color="primary" class="mb-3">
          Adicionar Atleta
        </v-btn>
      </div>

      <v-expansion-panels>
        <v-expansion-panel
          v-for="(form, index) in forms"
          :key="index"
          :value="isOpen(index)"
          @change="toggleCollapse(index)"
          class="mb-1"
        >
          <v-expansion-panel-header>
            <div class="row form-group">
              <div class="col-md-12">
                <h3 class="mb-0">
                  Atleta <span>{{ form.id ? form.nome : "novo" }}</span>
                </h3>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="col-md-12">
              <div class="row form-group">
                <div class="col-md-7">
                  <label for="nome" class="col-md-12">Nome:*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="nome"
                    v-model="form.nome"
                    placeholder="Digite o nome..."
                  />
                </div>

                <div class="col-md-3">
                  <label class="col-md-12">D. nascimento:*</label>
                  <input
                    required=""
                    type="date"
                    class="form-control"
                    id="form_control_1"
                    v-model="form.data_nascimento"
                    placeholder="Data de nascimento..."
                  />
                </div>
                <div class="col-md-2">
                  <label for="cpf" class="col-md-12">RG*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="cpf"
                    v-model="form.rg"
                    placeholder="Digite o rg ..."
                  />
                </div>
                <div class="col-md-2">
                  <label for="email" class="col-md-12">Email*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="email"
                    v-model="form.email"
                    placeholder="Digite o email ..."
                  />
                </div>
                <div class="col-md-2">
                  <label for="whatsapp" class="col-md-12">Whatsapp*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="whatsapp"
                    v-model="form.whatsapp"
                    placeholder="Digite o whatsapp ..."
                  />
                </div>
                <div class="col-md-2">
                  <label for="cpf" class="col-md-12">Altura*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="altura"
                    v-model="form.altura"
                    placeholder="Digite o altura ..."
                  />
                </div>
                <div class="col-md-2">
                  <label for="cpf" class="col-md-12">Peso*</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    id="peso"
                    v-model="form.peso"
                    placeholder="Digite o peso ..."
                  />
                </div>
                <div class="col-md-2">
                  <label for="tipo_sanguineo" class="col-md-12"
                    >Tipo Sanguíneo:</label
                  >
                  <treeselect
                    :multiple="false"
                    :options="tiposSanguineos"
                    placeholder="Selecione o Tipo sanguineo..."
                    v-model="form.tipo_sanguineo"
                  />
                </div>

                <div class="col-md-2">
                  <label class="col-md-12">Perfil:*</label>
                  <treeselect
                    disabled
                    :multiple="false"
                    :options="lista_perfil"
                    placeholder="Selecione o Perfil..."
                    v-model="form.perfil_id"
                  />
                </div>
                <div class="col-md-12">
                  <label class="col-md-12">Turmas:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_turmas"
                    placeholder="Selecione a Turma..."
                    v-model="form.turma_id"
                  />
                </div>

                <div class="col-md-10">
                  <label for="descricao" class="col-md-11 ml-2"
                    >Descricao:*</label
                  >
                  <textarea
                    type="text"
                    class="form-control"
                    id="descricao"
                    v-model="form.descricao"
                    placeholder="Digite o descricao..."
                  ></textarea>
                </div>
                <div class="col-md-12">
                  <v-checkbox
                    v-model="form.plano_saude"
                    label="Possui Plano de Saúde"
                  ></v-checkbox>
                </div>
                <div class="row col-md-12">
                  <div class="col-md-4">
                    <v-file-input
                      v-model="file[index].rg"
                      label="RG"
                      truncate-length="15"
                    ></v-file-input>
                  </div>
                  <div class="col-md-4">
                    <v-file-input
                      v-model="file[index].foto"
                      label="FOTO"
                      truncate-length="15"
                    ></v-file-input>
                  </div>
                </div>

                <div v-if="form.id" class="row col-md-12">
                <div
                  class="col-md-4"
                  v-for="(lista, j) in lista_campos[index].arquivos"
                  :key="j"
                >
                  <v-btn
                    class="mx-2"
                    :input-value="active"
                    depressed
                    rounded
                    @click.prevent="ircon(lista.id)"
                  >
                    {{ lista.nome_original.length > 15 ? lista.nome_original.slice(0, 7) + "..." + lista.nome_original.slice(-6) : lista.nome_original }}</v-btn
                  >
                </div>
              </div>

                <div class="row justify-content-end pt-5">
                  <div class="form-group row">
                    <div class="col-md-12 text-center">
                      <v-btn
                        @click.prevent="
                          confirm(index, !form.id ? 'cria' : 'edita')
                        "
                        color="primary"
                        :disabled="verif"
                      >
                        {{ !form.id ? "cria" : "edita" }}
                        <v-progress-circular
                          v-show="verif"
                          size="20"
                          color="white"
                        ></v-progress-circular>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-card class="mt-5" elevation="20">
        <div class="card-header">
          <h3 class="mb-0">Mensalidade</h3>
        </div>
        <div class="pr-2 pl-2">
          <b-table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            show-empty
            empty-text="Nenhum registro encontrado!"
            :fields="[
              'nome',
              'data_vencimento',
              'descricao',
              'valor',
              'status',
              'acoes',
            ]"
            :items="lista_mensalidade"
            :per-page="perPage"
            :current-page="currentPage"
            id="funcionarios-table"
          >
            <template #head(nome)>
              <span>Plano</span>
            </template>

            <template #cell(data_vencimento)="{ item }">
              {{ item.data_vencimento | formatDBDateToLocale }}
            </template>

            <template #cell(status)="data">
              <span
                class="label label-lg label-inline px-2 pb-2 pt-3"
                :class="{
                  'label-light-success': data.item.status === 1,
                  'label-light-danger': data.item.status === 0,
                  'label-light-warning': data.item.status === 2,
                }"
              >
                {{ data.item.status === 1 ? "andamento" : "pago" }}
              </span>
            </template>
            <template #cell(acoes)="{ item }">
              <div class="text-left w-100">
                <v-tooltip
                  v-for="(btn, index) in lista_btn"
                  :key="index"
                  top
                  :color="btn.color"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="lista_permissoes_filial[btn.permisao]"
                      fab
                      outlined
                      class="mr-1"
                      @click="acoes(item, btn.button)"
                      :color="btn.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i :class="btn.icon"></i>
                    </v-btn>
                  </template>
                  <span>{{ btn.title }}</span>
                </v-tooltip>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_mensalidade.length"
            :per-page="perPage"
            aria-controls="funcionarios-table"
          >
          </b-pagination>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import UsuarioService from "@/core/services/usuario.service";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";
// import InputFile from "@/core/helpers/InputFileMult";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [searchAddressMixin, fireAlert, dateFormat],
  // components: { InputFile },

  data() {
    return {
      usuid: UsuarioService.getUsuario()["usuario_id"],
      lista_btn: [
        {
          permisao: "u_Usuario",
          button: "pagar",
          title: "Pagar Mensalidade",
          color: "success",
          icon: "fas fa-money-bill  text-success",
        },
      ],
      tiposSanguineos: [
        { id: "A+", label: "A+" },
        { id: "A-", label: "A-" },
        { id: "B+", label: "B+" },
        { id: "B-", label: "B-" },
        { id: "AB+", label: "AB+" },
        { id: "AB-", label: "AB-" },
        { id: "O+", label: "O+" },
        { id: "O-", label: "O-" },
      ],
      forms: [],
      paramestado: "",
      value: "",
      loading: false,
      verif: false,
      variableWithMask: null,
      tel1: null,
      variableWithMaskTel: null,
      currentPage: 1,
      perPage: 5,
      host: process.env.VUE_APP_API_URL + "/arquivo/doc/",
      token: JwtService.getToken(),
      file: []
    };
  },

  computed: {
    // lista_atletas() {
    //   return this.$store.state.atleta.lista_atletas;
    // },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_mensalidade() {
      return this.$store.state.atleta.lista_mensalidade;
    },
    mensagem_alert() {
      return this.$store.state.atleta.mensagem_alert;
    },

    lista_perfil() {
      return this.$store.state.configEmpresa.lista_perfil.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },
    lista_turmas() {
      //     <span v-for="(dia,index) in JSON.parse(item.dias_semanas) " :key="index">
      //   {{ dia.diaDaSemana }}: {{ dia.horaInicio }} - {{ dia.horaFim }}<br />
      // </span>
      return this.$store.state.turma.lista_turmas.map((cat) => {
  const diasSemana = JSON.parse(cat.dias_semanas).map((dia) => dia.diaDaSemana + ' ' + dia.horaInicio + ' às ' + dia.horaFim);
  const label = `${cat.nome} : ${diasSemana.join(', ')}`;

  return {
    id: cat.id,
    label: label,
  };
});
    },
    lista_campos() {
      return this.$store.state.atleta.lista_atleta;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios" }]);
  },
  created() {
    this.listar_perfil();
    this.preenxerCampor();
  },
  watch: {
    tel1: function () {
      this.variableWithMaskTel = "(##)9####-####";

      this.form.whatsapp = this.tel1.replace(/[^0-9]/g, "");
      // this.form.ddd = this.form.whatsapp.substring(2, 4);
    },
  },
  methods: {
    toggleCollapse(index) {
      // Toggle the open/close state of the expansion panel at the given index
      this.forms[index].isOpen = !this.forms[index].isOpen;
    },

    isOpen(index) {
      // Return the open/close state of the expansion panel at the given index
      return this.forms[index].isOpen;
    },
    async listar_perfil() {
       this.$store
        .dispatch("turma/listar_turmas")
      // if (this.lista_campos == "") {
      //   await this.$store.dispatch(
      //     "atleta/listar_atleta",
      //     UsuarioService.getUsuario()["usuario_id"]
      //   );
      //   console.log(this.$store.state.atleta.lista_atleta);
      // }
      await this.$store.dispatch("configEmpresa/listar_perfil").finally(() => {
        // this.$store.dispatch("atualizar", "");
      });
    },
    async confirm(index, tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` un Perfil no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create(index);
            if (tipo == "edita") this.update(index);
          }
        },
      });
    },
    async create(index) {
  
      this.verif = true;
      await this.uprg(index)
      await this.upfoto(index)

      // if (this.$refs.arquivorg.file.length > 0) {
      //   let a = await this.$refs.arquivorg.submit();

      //   this.forms[index].arquivo_rg = a;
      // }
      // if (this.$refs.arquivofoto.file.length > 0) {
      //   let b = await this.$refs.arquivofoto.submit();

      //   this.forms[index].arquivo_foto = b;
      // }
    if( this.$route.params.usuario_id){
         this.forms[index].usuario_id = this.$route.params.usuario_id;

      }else{
        this.forms[index].usuario_id = this.usuid

      }


      let form = this.forms[index];
      await this.$store.dispatch("atleta/create_atleta", form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
      this.preenxerCampor()
      
    },
    async update(index) {
   

      this.verif = true;
      await this.uprg(index)
      await this.upfoto(index)

      if( this.$route.params.usuario_id){
        this.forms[index].usuario_id = this.$route.params.usuario_id;


      }else{
        this.forms[index].usuario_id = this.usuid

      }
      let form = this.forms[index];
      await this.$store.dispatch("atleta/update_atleta", form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
      });
      this.preenxerCampor()

    },

    adicionarFormulario() {
      // Adicionar um novo formulário ao array 'forms'
      this.forms.push({
        nome: null,
        rg: null,
        data_nascimento: null,
        status: 1,
        perfil_id: 5,
        altura: null,
        peso: null,
        tipo_sanguineo: null,
        whatsapp: null,
        plano_saude: null,
        usuario_id: null,
        arquivo_foto: null,
        arquivo_rg: null,
        turma_id: null,

      });
      this.file.push({ rg: null, foto: null });
    },



    async preenxerCampor() {
      let uuserr 
      if( this.$route.params.usuario_id){
       uuserr = this.$route.params.usuario_id;
      }else{
       uuserr =     this.usuid
      }

      await this.$store.dispatch(
        "atleta/listar_atleta",
        uuserr
      );

      if (this.lista_campos[0].id) {
        // await this.$store.dispatch(
        //   "atleta/listar_atleta_mensalidade",
        //   this.lista_campos[0].id
        // );

        this.lista_campos.forEach((element) => {
      this.file.push({ rg: null, foto: null });

          this.forms.push({
            id: element.id,
            nome: element.nome,
            data_nascimento: element.data_nascimento,
            rg: element.rg,
            peso: element.peso,
            altura: element.altura,
            tipo_sanguineo: element.tipo_sanguineo,
            email: element.email,
            whatsapp: element.whatsapp,
            plano_saude: element.plano_saude,
            perfil_id: element.perfil_id,
            usuario_id: element.usuario_id,
            turma_id: element.turma_id,

            arquivo_foto: null,
            arquivo_rg: null,
          });
        });
      }
    },
    ircon(value) {
      window.open(this.host + value + "?token=" + this.token, "_blank");
    },

    async uprg(index) {
      
        if (this.file[index].rg) {
       console.log(this.file[index].rg)
        this.loading = true;
        let i = 0;
        const formData = new FormData();
            formData.append("file[" + 0 + "]", this.file[index].rg);

        await ApiService.post("arquivo", formData)
          .then((res) => {
            // console.log(res)
            this.forms[index].arquivo_rg = (!res.data.id ? res.data : res.data.id);
            this.file[index].rg = null;

          })
          .finally(() => (this.loading = false));
      };
    },
    async upfoto(index) {
      
      if (this.file[index].foto) {
    
      this.loading = true;
      let i = 0;
      const formData = new FormData();
          formData.append("file[" + 0 + "]", this.file[index].foto);

      await ApiService.post("arquivo", formData)
        .then((res) => {
          // console.log(res)
          this.forms[index].arquivo_foto =(!res.data.id ? res.data : res.data.id);
          this.file[index].foto = null;
        })
        .finally(() => (this.loading = false));
    };
  },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>